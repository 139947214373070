import dinvyAscent from './assets/static/dinvyAscent/dinvyAscent.png';
import dashboard from './assets/static/dinvyAscent/dashboard.png';
import accounts from './assets/static/dinvyAscent/accounts.png';
import accountsCreate from './assets/static/dinvyAscent/accountsCreate.png';
import accountsDashboard from './assets/static/dinvyAscent/accountsDashboard.png';
import projects from './assets/static/dinvyAscent/projects.png';
import projectsCreate from './assets/static/dinvyAscent/projectsCreate.png';
import projectsDashboard from './assets/static/dinvyAscent/projectsDashboard.png';
import projectsTeam from './assets/static/dinvyAscent/projectsTeam.png';
import projectsBilling from './assets/static/dinvyAscent/projectsBilling.png';
import timesheetWeek from './assets/static/dinvyAscent/timesheetWeek.png';
import timesheetDay from './assets/static/dinvyAscent/timesheetDay.png';
import timesheetTimer from './assets/static/dinvyAscent/timesheetTimer.png';
import invoices from './assets/static/dinvyAscent/invoices.png';
import invoicesEdit from './assets/static/dinvyAscent/invoicesEdit.png';
import reportsSummary from './assets/static/dinvyAscent/reportsSummary.png';
import reportsDetail from './assets/static/dinvyAscent/reportsDetail.png';
import admin from './assets/static/dinvyAscent/admin.png';

const imageDinvyAscent = [
  dinvyAscent,
  dashboard,
  accounts,
  accountsCreate,
  accountsDashboard,
  projects,
  projectsCreate,
  projectsDashboard,
  projectsTeam,
  projectsBilling,
  timesheetWeek,
  timesheetDay,
  timesheetTimer,
  invoices,
  invoicesEdit,
  reportsSummary,
  reportsDetail,
  admin,
];

export default imageDinvyAscent;
