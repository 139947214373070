import React from 'react';
import '../assets/styles/components/Skills.scss';
import { ReactComponent as Angular } from '../assets/static/logos/angular.svg';
import { ReactComponent as Aws } from '../assets/static/logos/aws.svg';
import { ReactComponent as Bootstrap } from '../assets/static/logos/bootstrap.svg';
import { ReactComponent as Css } from '../assets/static/logos/css.svg';
import { ReactComponent as Docker } from '../assets/static/logos/docker.svg';
import { ReactComponent as Git } from '../assets/static/logos/git.svg';
import { ReactComponent as Html } from '../assets/static/logos/html.svg';
// import { ReactComponent as Java } from '../assets/static/logos/java.svg';
import { ReactComponent as Jira } from '../assets/static/logos/jira.svg';
import { ReactComponent as Js } from '../assets/static/logos/js.svg';
import { ReactComponent as MaterialUI } from '../assets/static/logos/material-ui.svg';
import { ReactComponent as Material } from '../assets/static/logos/material.svg';
import { ReactComponent as NestJS } from '../assets/static/logos/nest-js.svg';
// import { ReactComponent as NextJS } from '../assets/static/logos/next-js.svg';
import { ReactComponent as Ngrx } from '../assets/static/logos/ngrx.svg';
import { ReactComponent as NodeJS } from '../assets/static/logos/node-js.svg';
import { ReactComponent as Postgresql } from '../assets/static/logos/postgresql.svg';
import { ReactComponent as Python } from '../assets/static/logos/python.svg';
import { ReactComponent as ReactLogo } from '../assets/static/logos/react.svg';
import { ReactComponent as Redux } from '../assets/static/logos/redux.svg';
import { ReactComponent as Sass } from '../assets/static/logos/sass.svg';
import { ReactComponent as Ts } from '../assets/static/logos/ts.svg';

const Skills = () => {

  return (
    <section className='Skills'>
      <div className='Skills__container-text'>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <p>As a software developer, I fled from a traditional path and explored my skills, by trying on my own some languages ​​and frameworks / libraries. Software is more than just a tool, and I try to dig deep into it. Nowadays, I knack for critical thinking, empathy, and creativity to create viable and impactful solutions using these technologies.</p>
      </div>
      <div className='Skills__container-bar'>
        <div className='Skills__container-bar1'>
          <ul>
            <li>
              <ReactLogo className='Skills__container-barImg' id='reactLogo' />
              <h5>REACT</h5>
            </li>
            <li>
              <Js className='Skills__container-barImg' />
              <h5>JAVASCRIPT</h5>
            </li>
            <li>
              <Redux className='Skills__container-barImg' />
              <h5>REDUX</h5>
            </li>
            <li>
              <MaterialUI className='Skills__container-barImg' />
              <h5>MATERIAL UI</h5>
            </li>
            <li>
              <Html className='Skills__container-barImg' />
              <h5>HTML</h5>
            </li>
            <li>
              <Css className='Skills__container-barImg' />
              <h5>CSS</h5>
            </li>
            <li>
              <Bootstrap className='Skills__container-barImg' />
              <h5>BOOTSTRAP</h5>
            </li>
            {/* <li>
              <NextJS className='Skills__container-barImg' />
              <h5>NEXT JS</h5>
            </li> */}
          </ul>
        </div>
        <div className='Skills__container-bar1'>
          <ul>
            <li>
              <Angular className='Skills__container-barImg' />
              <h5>ANGULAR</h5>
            </li>
            <li>
              <Ts className='Skills__container-barImg' />
              <h5>TYPESCRIPT</h5>
            </li>
            <li>
              <Ngrx className='Skills__container-barImg' />
              <h5>NGRX</h5>
            </li>
            <li>
              <Material className='Skills__container-barImg' />
              <h5>MATERIAL</h5>
            </li>
            <li>
              <Sass className='Skills__container-barImg' />
              <h5>SASS</h5>
            </li>
            <li>
              <Aws className='Skills__container-barImg' />
              <h5>AWS</h5>
            </li>
            <li>
              <Jira className='Skills__container-barImg' />
              <h5>JIRA</h5>
            </li>
          </ul>
        </div>
        <div className='Skills__container-bar1'>
          <ul>
            <li>
              <Git className='Skills__container-barImg' />
              <h5>GIT</h5>
            </li>
            <li>
              <NodeJS className='Skills__container-barImg' />
              <h5>NODE JS</h5>
            </li>
            <li>
              <NestJS className='Skills__container-barImg' />
              <h5>NEST JS</h5>
            </li>
            <li>
              <Docker className='Skills__container-barImg' />
              <h5>DOCKER</h5>
            </li>
            <li>
              <Postgresql className='Skills__container-barImg' />
              <h5>POSTGRESQL</h5>
            </li>
            <li>
              <Python className='Skills__container-barImg' />
              <h5>PYTHON</h5>
            </li>
            <li />
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Skills;
