import React from 'react';
import '../assets/styles/components/About.scss';

const About = () => {
  return (
    <section className='About'>
      <div className='About__container-text'>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <p>
          Interdisciplinary Software Developer with experience in web technologies and engineering services. I worked at Neostella for 2 year as a Software Developer developing an online professional services management tool called Dinvy Ascent.
          Currently, most of my work experience is as a Front-end developer with the aim of helping companies, start-ups, and institutions to achieve their commercial objectives with productive designs and applications. However, I have been studying to become a Full-stack developer.
        </p>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <p>I'm a self-motivated learning enthusiast, currently improving my functional programming skills. Find some of my projects below! </p>
      </div>
    </section>
  );
};

export default About;
