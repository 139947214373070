import projects from './assets/static/hmvBQE/projects.png';
import budgets from './assets/static/hmvBQE/budgets.png';
import createBudgets from './assets/static/hmvBQE/createBudgets.png';

const imageHmvBqe = [
  projects,
  budgets,
  createBudgets,
];

export default imageHmvBqe;
