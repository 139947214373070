import home from './assets/static/fisentiva/home.png';
import servicios from './assets/static/fisentiva/servicios.png';
import contacto from './assets/static/fisentiva/contacto.png';

const imageFisentiva = [
  home,
  servicios,
  contacto,
];

export default imageFisentiva;
