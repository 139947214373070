import tesla from './assets/static/tesla.png';
import epm from './assets/static/epm.png';
import essa from './assets/static/essa.png';
import electricaribe from './assets/static/electricaribe.png';
import mrp from './assets/static/mrp.png';
import saesa from './assets/static/saesa.png';
import continental from './assets/static/continental.png';
import platzi from './assets/static/platzi.png';
import colombia from './assets/static/colombia.png';
import usa from './assets/static/usa.png';
import chile from './assets/static/chile.png';
import diploma1 from './assets/static/certificate/1.png';
import diploma2 from './assets/static/certificate/2.png';
import diploma3 from './assets/static/certificate/3.png';
import diploma4 from './assets/static/certificate/4.png';
import diploma5 from './assets/static/certificate/5.png';
import diploma6 from './assets/static/certificate/6.png';
import diploma7 from './assets/static/certificate/7.png';
import diploma8 from './assets/static/certificate/8.png';
import diploma9 from './assets/static/certificate/9.png';
import diploma10 from './assets/static/certificate/10.png';
import diploma11 from './assets/static/certificate/11.png';
import diploma12 from './assets/static/certificate/12.png';
import diploma13 from './assets/static/certificate/13.png';
import diploma14 from './assets/static/certificate/14.png';
import diploma15 from './assets/static/certificate/15.png';
import platzivideos from './assets/static/platzivideos.png';
import todoMachine from './assets/static/todo-machine.png';
import webBadges from './assets/static/web-badges.png';
import equipoDevelopers from './assets/static/equipoDevelopers.png';
import fisentiva from './assets/static/fisentiva.png';
import hmvBQE from './assets/static/hmvBQE.png';
import react from './assets/static/react.png';
import angular from './assets/static/angular.png';

const image = {
  tesla,
  epm,
  essa,
  electricaribe,
  mrp,
  saesa,
  platzi,
  continental,
  colombia,
  usa,
  chile,
  diploma1,
  diploma2,
  diploma3,
  diploma4,
  diploma5,
  diploma6,
  diploma7,
  diploma8,
  diploma9,
  diploma10,
  diploma11,
  diploma12,
  diploma13,
  diploma14,
  diploma15,
  react,
  angular,
  platzivideos,
  todoMachine,
  webBadges,
  equipoDevelopers,
  fisentiva,
  hmvBQE,
};

export default image;
