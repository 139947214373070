import React from 'react';
import '../assets/styles/components/Portfolio1.scss';

const Portfolio1 = (props) => {
  const { nameWeb, imgWeb, urlWeb, imgDevelop } = props;
  console.log(imgWeb);

  return (
    <section className='portfolio1'>
      <a className='portfolio1__link' href={urlWeb}>
        <div id='carouselExampleCaptions' className='carousel slide' data-bs-ride='carousel'>
          <div className='carousel-inner'>
            {
              imgWeb.map((item, index) => {
                return (
                  <div key={item} className={`carousel-item ${index === 0 ? 'active' : ''} `}>
                    <img src={item} className='d-block w-100' alt='...' />
                  </div>
                );
              })
            }
          </div>
          <div className='portfolio1__link-details'>
            <img src={imgDevelop} alt={nameWeb} />
            <h5>{nameWeb}</h5>
          </div>
        </div>
      </a>
    </section>
  );
};

export default Portfolio1;

