import login from './assets/static/dinvyClassic/login.png';
import dashboard from './assets/static/dinvyClassic/dashboard.png';
import accounts from './assets/static/dinvyClassic/accounts.png';
import accountsDashboard from './assets/static/dinvyClassic/accountsDashboard.png';
import projects from './assets/static/dinvyClassic/projects.png';
import projectsCreate from './assets/static/dinvyClassic/projectsCreate.png';
import projectsDashboard from './assets/static/dinvyClassic/projectsDashboard.png';
import planning from './assets/static/dinvyClassic/planning.png';
import timesheet from './assets/static/dinvyClassic/timesheet.png';
import invoice from './assets/static/dinvyClassic/invoice.png';
import invoiceClient from './assets/static/dinvyClassic/invoiceClient.png';
import invoicePayment from './assets/static/dinvyClassic/invoicePayment.png';
import invoiceStripe from './assets/static/dinvyClassic/invoiceStripe.png';
import admin from './assets/static/dinvyClassic/admin.png';

const imageDinvyClassic = [
  login,
  dashboard,
  accounts,
  accountsDashboard,
  projects,
  projectsCreate,
  projectsDashboard,
  planning,
  timesheet,
  invoice,
  invoiceClient,
  invoicePayment,
  invoiceStripe,
  admin,
];

export default imageDinvyClassic;
